import * as React from "react"
import {
  DataGrid,
  getRowId,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  plPL,
} from "@mui/x-data-grid"
import Preview from "./previewZgodneNiezgodne"
import * as adres from "./adresy.js"

let port = adres.config.url


const objectFindDiferences = (a, b) => {
  const aProps = Object.getOwnPropertyNames(a)
  const bProps = Object.getOwnPropertyNames(b)

  const diferences = aProps.filter((propName) => {
    return a[propName] !== b[propName]
  })

  return diferences
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  )
}

export default function DataTable() {
  const [locale, setLocale] = React.useState("plPL")
  const [rowsy, setRows] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(null)


  React.useEffect(() => {
    setLoading(true)
    fetch(port+"/wczytajProtokolNiezgodne", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setRows(data)
        console.log(data)
        setLoading(false)
      })
      .catch((error) => {
        setError(error)
        setLoading(false)
      })
  }, [])

  const columns = [
    { field: "ProtokolID", headerName: "ID", width: 70 },
    { field: "Nazwisko", headerName: "Kierowca", width: 250 },
    { field: "RegNo", headerName: "Nr rejestracyjny", width: 130 },
    {
      field: "CreatedDate",
      headerName: "Data",
      type: "date",
      width: 150,
    },
    {
      field: "Uwagi",
      headerName: "Uwagi",
      width: 150,
      // valueGetter: (params) =>
      //   `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    },
    {
      field: "akcje",
      headerName: "Akcje",
      width: 150,
      renderCell: (params) => <Preview stateChange={deleteItem} row={params.row} />,
    },
  
  ]

  const deleteItem = (id) => {
    console.log(id)
    console.log(rowsy)
    const newRows = rowsy.filter((row) => row.ProtokolID !== id)
    setRows(newRows)

    
    //setRows(rowsy.splice(id, 1))
  }

  const [selectionModel, setSelectionModel] = React.useState([])

  return (
    <div style={{ position: "relative", height: "100%", width: "100%" }}>
      <DataGrid
        localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
        components={{
          Toolbar: CustomToolbar,
        }}
        getRowId={(row) => row.ProtokolID}
        rows={rowsy}
        columns={columns}
        pageSize={12}
        rowsPerPageOptions={[12]}
        onSelectionModelChange={(newSelection) => {
          setSelectionModel(newSelection.selectionModel)
        }}
        selectionModel={selectionModel}
      />
    </div>
  )
}
