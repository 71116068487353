import "./index.css"
import React from "react"
import Navbar from "./navbar"
import Aside from "./aside"
import Body from "./body"

function App() {
  const [zakladka, setZakladka] = React.useState(0)
  const permissionGroup = {
    pytaniaSamochod: ["PROTOKOL_ADMIN", "PROTOKOL_ROOT"],
    pytaniaNaczepa: ["PROTOKOL_ADMIN", "PROTOKOL_ROOT"],
  }
  const handleZakladka = (zakladka) => {
    setZakladka(zakladka)
  }

  return (
    <div className="App">
      <Navbar />
      <Aside
        permissionGroup={permissionGroup}
        handleZakladka={handleZakladka}
      />
      <Body zakladka={zakladka} />
    </div>
  )
}

export default App
