import * as React from "react"
import Button from "@mui/material/Button"
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function changeOrder(props) {
  
  const handleClickUp = () => {
    props.reordering(props.row, -1)
  }
  
  const handleClickDown = () => {
   props.reordering(props.row, 1)
  }
  return (
    <div>
      {props.kierunek === "up" ? 
      <Button startIcon={<KeyboardArrowUpIcon/>} onClick={handleClickUp}></Button> : 
      <Button startIcon={<KeyboardArrowDownIcon/>} onClick={handleClickDown}></Button>}
    </div>
  )
}
