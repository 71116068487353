import React from "react"
import "./css/body.css"
import DataTable from "./datatable"
import ZgodneTruck from "./zgodneTruck"
import NiezgodneTruck from "./niezgodneTruck"
import PytaniaTruck from "./PytaniaTruck"
import Opony from "./opony"
import Zamowienia from "./zamowienia"

function Body(zakladka) {
  console.log(zakladka.zakladka)

  return (
    <div className="body">
      <div id="samochod-body" className="body-content">
        {zakladka.zakladka === 0 ? (
          <React.Fragment>
            <h3>Strona główna - samochód</h3>
            <DataTable />
          </React.Fragment>
        ) : zakladka.zakladka === 1 ? (
          <React.Fragment>
            <h3>Zgodne</h3>
            <ZgodneTruck />
          </React.Fragment>
        ) : zakladka.zakladka === 2 ? (
          <React.Fragment>
            <h3>Niezgodne</h3>
            <NiezgodneTruck />
          </React.Fragment>
        ) : zakladka.zakladka === 3 ? (
          <React.Fragment>
            <h3>Pytania</h3>
            <PytaniaTruck />
          </React.Fragment>
        ) : zakladka.zakladka === 4 ? (
          <React.Fragment>
            <h3>Opony</h3>
            <Opony />
          </React.Fragment>
        ) : zakladka.zakladka === 5 ? (
          <React.Fragment>
            <h3>Zamówienia</h3>
            <Zamowienia />
          </React.Fragment>
        ) :
        
        //naczepa
        zakladka.zakladka === "Naczepa0" ? (
          <React.Fragment>
            <h3>Strona główna - naczepa</h3>
            <DataTable />
          </React.Fragment>
        ) : zakladka.zakladka === "Naczepa1" ? (
          <React.Fragment>
            <h3>Zgodne</h3>
            <ZgodneTruck />
          </React.Fragment>
        ) : zakladka.zakladka === "Naczepa2" ? (
          <React.Fragment>
            <h3>Niezgodne</h3>
            <NiezgodneTruck />
          </React.Fragment>
        ) : zakladka.zakladka === "Naczepa3" ? (
          <React.Fragment>
            <h3>Pytania</h3>
            <PytaniaTruck />
          </React.Fragment>
        ) : zakladka.zakladka === "Naczepa4" ? (
          <React.Fragment>
            <h3>Opony</h3>
            <Opony />
          </React.Fragment>
        ) : null}
      </div>
    </div>
  )
}

export default Body
