import * as React from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import * as adres from "./adresy.js"

let port = adres.config.url


export default function AlertDialog(props) {
  const [open, setOpen] = React.useState(false);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    setOpen(false);
    //delete from pytania
    fetch(port+`/pytania/${props.row._id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      }
    })
      .then((response) => {
        props.delete(props.row)
        if (response.ok) {
          return response.json()
        }
        throw response
      })
      .then((data) => {
        //sort by page 
        props.delete(props.row)
      })
     
  };

  return (
    <div>

      <IconButton color="primary" onClick={handleClickOpen}>
        <DeleteIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Usuń"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           Czy na pewno chcesz usunąć pytanie?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Nie</Button>
          <Button onClick={handleDelete} autoFocus>
            Tak
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
