import React from "react"
import ReactDOM from "react-dom"
import { InputLabel } from "@mui/material"
//import NotchedOutline from "@mui/core/OutlinedInput/NotchedOutline"
import { withStyles } from "@mui/styles"
import clsx from "clsx"

const styles = {
  notchedOutline: {
    borderRadius: "10px !important",
    display: "flex !important",
    alignItems: "center",
    // borderColor: "red !important",
  },
  root: {
    borderRadius: "10px !important",
    //borderColor: "transparent !important",
    border: "1px solid rgba(0, 0, 0, 0.26) !important",
    backgroundColor: "white",
    position: "absolute",
    width: "250px",
  },
  contentWrapper: {
    position: "relative",
  },
  content: {
    display: "flex !important",
    alignItems: "center",
    padding: "20px 10px 10px 10px",
  },
  inputLabel: {
    position: "absolute",
    //backgroundColor: "white !important",
    left: 10,
    top: 10,
    fontsize: "0.5rem",
    // slight alteration to spec spacing to match visual spec result
    transform: "translate(0, -9px) scale(0.8)",
  },
}

const LabelledOutline = ({
  classes,
  id,
  label,
  children,
  className,
  stylowa,
}) => {
  const [labelWidth, setLabelWidth] = React.useState(0)
  const labelRef = React.useRef(null)
  React.useEffect(() => {
    const labelNode = ReactDOM.findDOMNode(labelRef.current)
    setLabelWidth(labelNode != null ? labelNode.offsetWidth : 0)
  }, [label])

  return (
    <div className={clsx(className, classes.root)} style={stylowa}>
      <InputLabel
        ref={labelRef}
        htmlFor={id}
        variant="outlined"
        className={classes.inputLabel}
        shrink
      >
        {label}
      </InputLabel>
      <div className={classes.contentWrapper}>
        <div id={id} className={classes.content}>
          {children}
        </div>
      </div>
    </div>
  )
}
export default withStyles(styles)(LabelledOutline)
