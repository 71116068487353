import React, { useState } from "react"
import { Chart } from "primereact/chart"

const Wykres = (dataSet) => {
  const [basicData] = useState(dataSet.dataSet)

  console.log("dataSet", dataSet)
  const getLightTheme = () => {
    let basicOptions = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          labels: {
            color: "#495057",
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
        },
        y: {
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
          min: 0,
          max: 20,
        },
      },
    }

    let multiAxisOptions = {
      stacked: false,
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          labels: {
            color: "#495057",
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
        },
        y: {
          type: "linear",
          display: true,
          position: "left",
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
        },
        y1: {
          type: "linear",
          display: true,
          position: "right",
          ticks: {
            color: "#495057",
          },
          grid: {
            drawOnChartArea: false,
            color: "#ebedef",
          },
        },
      },
    }

    return {
      basicOptions,
      multiAxisOptions,
    }
  }

  const { basicOptions, multiAxisOptions } = getLightTheme()
  console.log("chartname", dataSet.chartName)
  return (
    <div>
      <div className="cardchart">
        <h5>{dataSet.chartName}</h5>
        <Chart type="line" data={basicData} options={basicOptions} />
      </div>
    </div>
  )
}
export default Wykres
