import * as React from "react"
import "./css/preview.css"
import ManageSearchIcon from "@mui/icons-material/ManageSearch"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Fade from "@mui/material/Fade"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Container from "@mui/material/Container"
import TaskAltIcon from "@mui/icons-material/TaskAlt"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Switch from "@mui/material/Switch"
import oponki from "./images/opony.png"
import Wykres from "./wykres"
import TextField from "@mui/material/TextField"
import GrupaDIV from "./GrupaDIV"
import * as adres from "./adresy.js"

let port = adres.config.url

Object.filter = (obj, predicate) =>
  Object.keys(obj)
    .filter((key) => predicate(obj[key]))
    .reduce((res, key) => Object.assign(res, { [key]: obj[key] }), {})

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "80%",
  height: "780px",
  overflowY: "scroll",
  zIndex: 999,
}

export default function TransitionsModal(row) {
  console.log(row)
  const [tyre, setTyre] = React.useState({
    LF: { Bieznik: 0, Marka: "" },
    RF: { Bieznik: 0, Marka: "" },
    LBZ: { Bieznik: 0, Marka: "" },
    LBW: { Bieznik: 0, Marka: "" },
    RBZ: { Bieznik: 0, Marka: "" },
    RBW: { Bieznik: 0, Marka: "" },
  })

  const [openChart, setOpenChart] = React.useState(false)
  const [chartData, setChartData] = React.useState({})
  const [LFhidden, setLFhidden] = React.useState(false)
  const [RFhidden, setRFhidden] = React.useState(false)
  const [LBZhidden, setLBZhidden] = React.useState(true)
  const [LBWhidden, setLBWhidden] = React.useState(true)
  const [RBZhidden, setRBZhidden] = React.useState(true)
  const [RBWhidden, setRBWhidden] = React.useState(true)

  const fetchowanie = async (row) => {
    fetch(port + "/opony", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: row.row.ID,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw response
      })
      .then((data) => {
        //sort by date
        console.log(data)
        if (data.length > 0) {
          data.sort((a, b) => {
            return new Date(b.Data) - new Date(a.Data)
          })
          console.log(data)
          const newest = Object.filter(data, (x) => x.Data === data[0].Data)
          const oponki = {}

          Object.keys(newest).forEach((key) => {
            oponki[newest[key].Opona] = newest[key]
          })
          console.log(oponki)
          setTyre(oponki)

          data.sort((a, b) => {
            return new Date(a.Data) - new Date(b.Data)
          })
          console.log(data)

          let chartLabels = []
          let chartDataset = {}

          Object.keys(data).forEach((key) => {
            chartLabels.push(data[key].Data)
          })

          Object.keys(data).forEach((key) => {
            //adding to object chartDataset if key doesnt exist
            if (!chartDataset[data[key].Opona]) {
              chartDataset[data[key].Opona] = []
            }
            //adding to object chartDataset if key exist
            chartDataset[data[key].Opona].push(data[key].Bieznik)
            //chartDataset[data[key].Opona].push({[data[key].Data]: [data[key].Bieznik, data[key].Marka]})
          })

          console.log(Object.values(chartDataset.LF))

          //remove duplicates
          chartLabels = [...new Set(chartLabels)]

          console.log(chartLabels)
          setChartData({
            labels: chartLabels,
            datasets: [
              {
                label: "Lewy przód",
                data: Object.values(chartDataset.LF),
                fill: false,
                borderColor: "#42A5F5",
                tension: 0.4,
                hidden: LFhidden,
              },
              {
                label: "Prawy przód",
                data: Object.values(chartDataset.RF),
                fill: false,
                borderColor: "#FFA726",
                tension: 0.4,
                hidden: RFhidden,
              },
              {
                label: "Lewy tył zewnętrzny",
                data: Object.values(chartDataset.LBZ),
                fill: false,
                borderColor: "#00A726",
                tension: 0.4,
                hidden: LBZhidden,
              },
              {
                label: "Lewy tył wewnętrzny",
                data: Object.values(chartDataset.LBW),
                fill: false,
                borderColor: "#FF0000",
                tension: 0.4,
                hidden: LBWhidden,
              },
              {
                label: "Prawy tył zewnętrzny",
                data: Object.values(chartDataset.RBZ),
                fill: false,
                borderColor: "#FF00F",
                tension: 0.4,
                hidden: RBZhidden,
              },
              {
                label: "Prawy tył wewnętrzny",
                data: Object.values(chartDataset.RBW),
                fill: false,
                borderColor: "#906090",
                tension: 0.4,
                hidden: RBWhidden,
              },
            ],
          })
        }
      })
  }

  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const podgladClick = () => {
    handleOpen()
    fetchowanie(row)
  }
  const handleSwitch = () => {
    setOpenChart(!openChart)
  }
  const handleSwitchOpona = (opona) => {
    //switch do zmiany widoczności danych na wykresie
    switch (opona) {
      case "LF":
        chartData.datasets[0].hidden = !LFhidden
        setLFhidden(!LFhidden)
        break
      case "RF":
        chartData.datasets[1].hidden = !RFhidden
        setRFhidden(!RFhidden)
        break
      case "LBZ":
        chartData.datasets[2].hidden = !LBZhidden
        setLBZhidden(!LBZhidden)
        break
      case "LBW":
        chartData.datasets[3].hidden = !LBWhidden
        setLBWhidden(!LBWhidden)
        break
      case "RBZ":
        chartData.datasets[4].hidden = !RBZhidden
        setRBZhidden(!RBZhidden)
        break
      case "RBW":
        chartData.datasets[5].hidden = !RBWhidden
        setRBWhidden(!RBWhidden)
        break
      default:
        break
    }

    setChartData(chartData)
  }

  return (
    <div>
      <Button startIcon={<ManageSearchIcon />} onClick={podgladClick}>
        Podgląd
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Box sx={style}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Podgląd: {row.row.RegNo}
              </Typography>
              <FormGroup>
                <FormControlLabel
                  label="Wykres zużycia opon"
                  control={
                    <Switch id="Wykres" name="Wykres" onChange={handleSwitch} />
                  }
                />
              </FormGroup>
            </div>
            <Container sx={{ height: 400, width: "100%", marginTop: "7%" }}>
              {open && !openChart && (
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    marginTop: "40px",
                  }}
                >
                  <img src={oponki} id="truck" />
                  <GrupaDIV
                    label="Lewy przód"
                    stylowa={{ left: "-130px", top: "310px" }}
                  >
                    <TextField
                      disabled
                      sx={{
                        width: "10ch",
                        marginRight: "5px",
                      }}
                      margin="dense"
                      size="small"
                      id="outlined-disabled"
                      label="Bieżnik"
                      value={tyre.LF.Bieznik}
                      //defaultValue={tyre.LF.Bieznik}
                    />
                    <TextField
                      disabled
                      sx={{
                        width: "15ch",
                        WebkitTextFillColor: "#909090",
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#909090",
                        },
                      }}
                      size="small"
                      margin="dense"
                      id="outlined-disabled"
                      label="Marka"
                      value={tyre.LF.Marka}
                    />
                  </GrupaDIV>
                  <GrupaDIV
                    label="Prawy przód"
                    stylowa={{ left: "-130px", top: "5px" }}
                  >
                    <TextField
                      disabled
                      sx={{
                        width: "10ch",
                        marginRight: "5px",
                      }}
                      margin="dense"
                      size="small"
                      id="outlined-disabled"
                      label="Bieżnik"
                      value={tyre.RF.Bieznik}
                    />
                    <TextField
                      disabled
                      sx={{
                        width: "15ch",
                        WebkitTextFillColor: "#909090",
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#909090",
                        },
                      }}
                      size="small"
                      margin="dense"
                      id="outlined-disabled"
                      label="Marka"
                      value={tyre.RF.Marka}
                    />
                  </GrupaDIV>
                  <GrupaDIV
                    label="Lewy tył zewnętrzny"
                    stylowa={{ right: "-130px", top: "310px" }}
                  >
                    <TextField
                      disabled
                      sx={{
                        width: "10ch",
                        marginRight: "5px",
                      }}
                      margin="dense"
                      size="small"
                      id="outlined-disabled"
                      label="Bieżnik"
                      value={tyre.LBZ.Bieznik}
                    />
                    <TextField
                      disabled
                      sx={{
                        width: "15ch",
                        WebkitTextFillColor: "#909090",
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#909090",
                        },
                      }}
                      size="small"
                      margin="dense"
                      id="outlined-disabled"
                      label="Marka"
                      value={tyre.LBZ.Marka}
                    />
                  </GrupaDIV>
                  <GrupaDIV
                    label="Lewy tył wewnętrzny"
                    stylowa={{ right: "-130px", top: "222px" }}
                  >
                    <TextField
                      disabled
                      sx={{
                        width: "10ch",
                        marginRight: "5px",
                      }}
                      margin="dense"
                      size="small"
                      id="outlined-disabled"
                      label="Bieżnik"
                      value={tyre.LBW.Bieznik}
                    />
                    <TextField
                      disabled
                      sx={{
                        width: "15ch",
                        WebkitTextFillColor: "#909090",
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#909090",
                        },
                      }}
                      size="small"
                      margin="dense"
                      id="outlined-disabled"
                      label="Marka"
                      value={tyre.LBW.Marka}
                    />
                  </GrupaDIV>
                  <GrupaDIV
                    label="Prawy tył zewnętrzny"
                    stylowa={{ right: "-130px", top: "5px" }}
                  >
                    <TextField
                      disabled
                      sx={{
                        width: "10ch",
                        marginRight: "5px",
                      }}
                      margin="dense"
                      size="small"
                      id="outlined-disabled"
                      label="Bieżnik"
                      value={tyre.RBZ.Bieznik}
                    />
                    <TextField
                      disabled
                      sx={{
                        width: "15ch",
                        WebkitTextFillColor: "#909090",
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#909090",
                        },
                      }}
                      size="small"
                      margin="dense"
                      id="outlined-disabled"
                      label="Marka"
                      value={tyre.RBZ.Marka}
                    />
                  </GrupaDIV>
                  <GrupaDIV
                    label="Prawy tył wewnętrzny"
                    stylowa={{ right: "-130px", top: "93px" }}
                  >
                    <TextField
                      disabled
                      sx={{
                        width: "10ch",
                        marginRight: "5px",
                      }}
                      margin="dense"
                      size="small"
                      id="outlined-disabled"
                      label="Bieżnik"
                      value={tyre.RBW.Bieznik}
                    />
                    <TextField
                      disabled
                      sx={{
                        width: "15ch",
                        WebkitTextFillColor: "#909090",
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#909090",
                        },
                      }}
                      size="small"
                      margin="dense"
                      id="outlined-disabled"
                      label="Marka"
                      value={tyre.RBW.Marka}
                    />
                  </GrupaDIV>
                </div>
              )}
              {open && openChart && (
                <div>
                  <Wykres chartName="Oponeo" dataSet={chartData}></Wykres>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        label="Lewy przód"
                        control={
                          <Switch
                            id="WykresLF"
                            name="WykresLF"
                            checked={!LFhidden}
                            onChange={() => handleSwitchOpona("LF")}
                          />
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        label="Prawy przód"
                        control={
                          <Switch
                            id="WykresRF"
                            name="WykresRF"
                            checked={!RFhidden}
                            onChange={() => handleSwitchOpona("RF")}
                          />
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        label="Lewy tył zewnętrzny"
                        control={
                          <Switch
                            id="WykresLBZ"
                            name="WykresLBZ"
                            checked={!LBZhidden}
                            onChange={() => handleSwitchOpona("LBZ")}
                          />
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        label="Lewy tył wewnętrzny"
                        control={
                          <Switch
                            id="WykresLBW"
                            name="WykresLBW"
                            checked={!LBWhidden}
                            onChange={() => handleSwitchOpona("LBW")}
                          />
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        label="Prawy tył zewnętrzny"
                        control={
                          <Switch
                            id="WykresRBZ"
                            name="WykresRBZ"
                            checked={!RBZhidden}
                            onChange={() => handleSwitchOpona("RBZ")}
                          />
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        label="Prawy tył wewnętrzny"
                        control={
                          <Switch
                            id="WykresRBW"
                            name="WykresRBW"
                            checked={!RBWhidden}
                            onChange={() => handleSwitchOpona("RBW")}
                          />
                        }
                      />
                    </FormGroup>
                  </div>
                </div>
              )}
            </Container>
            <Typography
              id="transition-modal-description"
              sx={{
                mt: 2,
                display: "flex",
                position: "fixed",
                width: "100%",
                justifyContent: "center",
                flexDirection: "row",
                bottom: "20px",
              }}
            >
              <Button
                variant="contained"
                startIcon={<TaskAltIcon />}
                className="material-button"
                onClick={handleClose}
              >
                Zatwierdź
              </Button>
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  )
}
