import * as React from "react"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Fade from "@mui/material/Fade"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import TextField from "@mui/material/TextField"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import CheckIcon from "@mui/icons-material/Check"
import FormLabel from "@mui/material/FormLabel"
import { plPL, DataGrid } from "@mui/x-data-grid"
import AddCircleSharpIcon from "@mui/icons-material/AddCircleSharp"
import IconButton from "@mui/material/IconButton"
import * as adres from "./adresy.js"

let port = adres.config.url

const style = {
  display: "grid",
  gridTemplateAreas: `"header header"
  "contentID content1"
  "contentPolski content1"
  "contentOnucowy content1"
  "contentStrona content1"
  "contentTyp content1"
  "footer footer"`,
  gridAutoFlow: "column",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "80%",
  overflowY: "scroll",
  zIndex: 999,
}

export default function AddEditQuest(props) {
  const ref = React.useRef("modal")
  const pytankoID = React.useRef("id")
  const polskiNazwa = React.useRef("nazwaPolska")
  const rosyjskiNazwa = React.useRef("nazwaRosyjska")
  const stronaNumer = React.useRef("strona")
  const typPola = React.useRef("typ")
  const rodzajData = React.useRef()
  const rodzajJednostka = React.useRef()
  const rodzajRadio = React.useRef()

  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [typ, setTyp] = React.useState("")
  const [columns1, setColumns1] = React.useState([])
  const [jednostka, setJednostka] = React.useState("")
  const [details, setDetails] = React.useState(<></>)
  const [doRadia, setDoRadia] = React.useState([])
  const [dateVal, setDateVal] = React.useState("day")

  const [selectionModel, setSelectionModel] = React.useState([])

  const podgladClick = () => {
    handleOpen()
    if (props.row.type === "date") {
      setDetails("date")
    }
    if (props.row.type === "number") {
      setDetails("number")
    }
    if (props.row.type === "checkbox") {
      setDetails("checkbox")
    }
    if (props.row.type === "radio2") {
      setDetails("radio2")
    }
    if (props.row.type === "text") {
      setDetails("text")
    }
    if (props.row.type === "image") {
      setDetails("image")
    }
  }
  React.useEffect(() => {
    const kolumny1 = [
      {
        field: "id",
        headerName: "ID",
        type: "number",
        selectionModel: "none",
      },
      {
        field: "Polski",
        headerName: "Polski",
        width: 150,
        editable: true,
        valueGetter: (params) => {
          return params.value
        },
      },
      {
        field: "Rosyjski",
        headerName: "Rosyjski",
        width: 150,
        editable: true,
        valueGetter: (params) => {
          return params.value
        },
      },
    ]
    setColumns1(kolumny1)
  }, [])

  const handleDateChange = (event) => {
    setDateVal(event.target.value)
  }

  const handleConfirm = () => {
    let opcje = {}
    const polskiArray = Object.keys(doRadia).map((key) => {
      return doRadia[key].Polski
    })
    const rosyjskiArray = Object.keys(doRadia).map((key) => {
      return doRadia[key].Rosyjski
    })

    switch (details) {
      case "radio2":
        opcje = {
          polski: polskiArray,
          onucowy: rosyjskiArray,
        }
        break
      case "number":
        opcje = {
          polski: props.row.options.polski,
          onucowy: props.row.options.onucowy,
        }
        break
      case "date":
        opcje = {
          polski: dateVal,
          onucowy: dateVal,
        }
        break
      case "checkbox":
        opcje = {
          polski: polskiArray,
          onucowy: rosyjskiArray,
        }

        break
      case "text":
        opcje = {
          polski: "",
          onucowy: "",
        }
        break
      case "image":
        opcje = {
          polski: "",
          onucowy: "",
        }
        break
      default:
        break
    }
    let maxSeqNumber = 0
    if(props.pytania){
   props.pytania.forEach((row) => {
      if (row.page === props.row.page) {
        if (row.seqNumber > maxSeqNumber) {
          maxSeqNumber = row.seqNumber
        }
      }
    })}
    const newSeqNumber = maxSeqNumber + 1

    const pytanieObj = {
      _id: props.row._id,
      page: parseInt(stronaNumer.current.lastChild.firstChild.value),
      id: pytankoID.current.lastChild.firstChild.value,
      question: {
        polski: polskiNazwa.current.lastChild.firstChild.value,
        onucowy: rosyjskiNazwa.current.lastChild.firstChild.value,
      },
      type: details,
      required: props.row.required,
      jednostka: jednostka ? jednostka : props.row.jednostka,
      options: opcje,
      ilosc: props.row.ilosc,
      image: props.row.image,
      seqNumber: props.row.seqNumber ? props.row.seqNumber : newSeqNumber,
    }
    
if (props.icon == "Edycja") {
    fetch(port+`/pytania/${props.row._id}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(pytanieObj),
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw response
      })
      .then((data) => {
        //sort by page
        props.edit(pytanieObj)
      })    
  } 
  else if (props.icon == "Dodaj"){
    fetch(port+"/pytania", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(pytanieObj),
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw response
      })
      .then((data) => {
        //sort by page
        props.add(pytanieObj)
      })
    
  }
    handleClose()
  }

  const handleAddRow = () => {
    setDoRadia((prevRows) => [
      ...prevRows,
      { id: doRadia.length + 1, Polski: "", Rosyjski: "" },
    ])
  }

  const handleDeleteRow = (e) => {
    setDoRadia((prevRows) => {
      const rows = [...prevRows]
      const newRows = rows.filter((row) => row.id !== selectionModel[0])
      //change next ID's
      newRows.forEach((row) => {
        if (row.id > selectionModel[0]) {
          row.id = row.id - 1
        }
      })
      return newRows
    })
  }

  const handleCellEditStop = (e, event) => {
    setDoRadia((prevRows) => {
      const rows = [...prevRows]
      rows[e.id - 1][e.field] = e.value
      return rows
    })
  }

  React.useEffect(() => {
    let radioOptions = []
    if (props.row.type === "radio2" || props.row.type === "checkbox") {
      if (props.row.options.polski !== "") {
        Object.keys(props.row.options.polski).forEach((i) => {
          radioOptions.push({
            id: i * 1 + 1,
            Polski: props.row.options.polski[i],
            Rosyjski: props.row.options.onucowy[i],
          })
        })
        setDoRadia(radioOptions)
      }
    }
  }, [])

  const handleChange = (event) => {
    setTyp(event.target.value)
    setDetails(event.target.value)
  }
  const handleJednostka = (event) => {
    setJednostka(event.target.value)
  }

const uniqueID = Date.now() + "-" + Math.round(Math.random() * 1e6)

  return (
    <div>
      {props.icon === "Edycja" ? (
        <Button startIcon={<ModeEditIcon />} onClick={podgladClick}></Button>
      ) : (
        <IconButton
        color="primary"
          onClick={podgladClick}
          sx={{
            fontSize: 100,
            position: "fixed",
            bottom: 16,
            right: 16,
            size: "large",
          }}
        >
          <AddCircleSharpIcon sx={{ fontSize: 80 }} />
        </IconButton>
      )}
      <Modal 
        ref={ref}
        disableEnforceFocus
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{ gridArea: "header" }}
            >
              {props.icon === "Edycja" ? (
                <>Edycja pytania</>
              ) : (
                <>Nowe pytanie</>
              )}
            </Typography>

            <Box
              sx={{
                "& > :not(style)": { m: 1, width: "40ch" },
                gridArea: "contentID",
              }}
            >
              <TextField
                id="pytankoID"
                ref={pytankoID}
                label="ID"
                type="search"
                variant="filled"
                defaultValue={props.row.id ? props.row.id : uniqueID}
                required
                disabled
              />
            </Box>
            <Box
              sx={{
                "& > :not(style)": { m: 1, width: "40ch" },
                gridArea: "contentPolski",
              }}
            >
              <TextField
                id="polskiNazwa"
                ref={polskiNazwa}
                label="Pytanie - polski"
                type="search"
                variant="filled"
                defaultValue={
                  props.row.question.polski ? props.row.question.polski : ""
                }
                required
              />
            </Box>
            <Box
              sx={{
                "& > :not(style)": { m: 1, width: "40ch" },
                gridArea: "contentOnucowy",
              }}
            >
              <TextField
                id="rosyjskiNazwa"
                ref={rosyjskiNazwa}
                label="Pytanie - rosyjski"
                type="search"
                variant="filled"
                defaultValue={
                  props.row.question.onucowy ? props.row.question.onucowy : ""
                }
                required
              />
            </Box>
            <Box
              sx={{
                "& > :not(style)": { m: 1, width: "40ch" },
                gridArea: "contentStrona",
              }}
            >
              <TextField
                id="pageNumber"
                ref={stronaNumer}
                label="Nr strony"
                type="search"
                variant="filled"
                defaultValue={props.row.page ? props.row.page : ""}
                required
              />
            </Box>
            <Box
              sx={{
                "& > :not(style)": { m: 1, width: "40ch" },
                gridArea: "contentTyp",
              }}
            >
              <FormControl fullWidth required>
                <InputLabel id="typPolaLabel">Typ pola</InputLabel>
                <Select
                  labelId="typPolaLabel"
                  id="typPola"
                  ref={typPola}
                  value={typ ? typ : props.row.type ? props.row.type : ""}
                  label="Typ pola"
                  onChange={handleChange}
                >
                  <MenuItem value="date">Data</MenuItem>
                  <MenuItem value="checkbox">Checkbox</MenuItem>
                  <MenuItem value="number">Liczba</MenuItem>
                  <MenuItem value="radio2">Radio</MenuItem>
                  <MenuItem value="image">Zdjęcie</MenuItem>
                  <MenuItem value="textarea">Tekst</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                "& > :not(style)": { m: 1, width: "25ch" },
                gridArea: "content1",
              }}
            >
              {details === "date" && (
                <FormControl required>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Rodzaj pola
                  </FormLabel>
                  <br />
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="day"
                    name="radio-buttons-group"
                    ref={rodzajData}
                    onChange={handleDateChange}
                  >
                    <FormControlLabel
                      value="day"
                      control={<Radio />}
                      label="Dzień"
                    />
                    <FormControlLabel
                      value="month"
                      control={<Radio />}
                      label="Miesiąc"
                    />
                  </RadioGroup>
                </FormControl>
              )}
              {details === "checkbox" && (
                <div>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Rodzaj pola
                  </FormLabel>
                  <br />
                  <Button size="small" onClick={handleAddRow}>
                    Dodaj opcję
                  </Button>
                  <Button size="small" onClick={handleDeleteRow}>
                    Usuń opcję
                  </Button>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      height: "400px",
                      width: "400px",
                    }}
                  >
                    <DataGrid
                      localeText={
                        plPL.components.MuiDataGrid.defaultProps.localeText
                      }
                      onSelectionModelChange={(newSelectionModel) => {
                        setSelectionModel(newSelectionModel)
                      }}
                      selectionModel={selectionModel}
                      rows={doRadia}
                      columns={columns1}
                      ref={rodzajRadio}
                      onCellEditCommit={handleCellEditStop}
                    />
                  </Box>
                </div>
              )}
              {details === "number" && (
                <FormControl fullWidth required>
                  <InputLabel id="jednostkaLabel">Jednostka</InputLabel>
                  <Select
                    labelId="jednostkaLabel"
                    id="jednostka"
                    ref={rodzajJednostka}
                    value={
                      jednostka
                        ? jednostka
                        : props.row.jednostka
                        ? props.row.jednostka
                        : ""
                    }
                    label="Jednostka"
                    onChange={handleJednostka}
                    
                  >
                    <MenuItem value="km">Kilometry</MenuItem>
                    <MenuItem value="litrów">Litry</MenuItem>
                    <MenuItem value="szt.">Sztuki</MenuItem>
                    <MenuItem value="mm">Milimetry</MenuItem>
                    <MenuItem value="cm">Centymetry</MenuItem>
                  </Select>
                </FormControl>
              )}
              {details === "radio2" && (
                <div>
                  <Button size="small" onClick={handleAddRow}>
                    Dodaj opcję
                  </Button>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      height: "400px",
                      width: "400px",
                    }}
                  >
                    <DataGrid
                      onSelectionModelChange={(newSelectionModel) => {
                        setSelectionModel(newSelectionModel)
                      }}
                      selectionModel={selectionModel}
                      localeText={
                        plPL.components.MuiDataGrid.defaultProps.localeText
                      }
                      rows={doRadia}
                      columns={columns1}
                      ref={rodzajRadio}
                      onCellEditCommit={handleCellEditStop}
                    />
                  </Box>
                </div>
              )}
            </Box>
            <Box
              sx={{
                "& > :not(style)": { m: 1, width: "25ch" },
                gridArea: "footer",
                textAlign: "center",
              }}
            >
              <Button
                endIcon={<CheckIcon />}
                variant="contained"
                onClick={handleConfirm}
              >
                Zatwierdź
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  )
}
