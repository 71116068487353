import React, { useState } from 'react';
import { Password } from 'primereact/password';
import { InputText } from 'primereact/inputtext';
import logo from './images/logo.png';
import { Button } from "primereact/button";
import './css/login.css';

// import 'primereact/resources/themes/bootstrap4-light-blue/theme.css'
// import 'primereact/resources/themes/bootstrap4-light-purple/theme.css'
// import 'primereact/resources/themes/bootstrap4-dark-blue/theme.css'
// import 'primereact/resources/themes/bootstrap4-dark-purple/theme.css'
 import 'primereact/resources/themes/md-light-indigo/theme.css'
// import 'primereact/resources/themes/md-light-deeppurple/theme.css'
// import 'primereact/resources/themes/md-dark-indigo/theme.css'
// import 'primereact/resources/themes/md-dark-deeppurple/theme.css'
// import 'primereact/resources/themes/mdc-light-indigo/theme.css'
// import 'primereact/resources/themes/mdc-light-deeppurple/theme.css'
// import 'primereact/resources/themes/mdc-dark-indigo/theme.css'
// import 'primereact/resources/themes/mdc-dark-deeppurple/theme.css'
// import 'primereact/resources/themes/fluent-light/theme.css'
// import 'primereact/resources/themes/lara-light-blue/theme.css'
// import 'primereact/resources/themes/lara-light-indigo/theme.css'
// import 'primereact/resources/themes/lara-light-purple/theme.css'
//+ import 'primereact/resources/themes/lara-light-teal/theme.css'
//  import 'primereact/resources/themes/lara-dark-blue/theme.css'
// import 'primereact/resources/themes/lara-dark-indigo/theme.css'
// import 'primereact/resources/themes/lara-dark-purple/theme.css'
//+ import 'primereact/resources/themes/lara-dark-teal/theme.css'
// import 'primereact/resources/themes/saga-blue/theme.css'
//+ import 'primereact/resources/themes/saga-green/theme.css'
// import 'primereact/resources/themes/saga-orange/theme.css'
// import 'primereact/resources/themes/saga-purple/theme.css'
// import 'primereact/resources/themes/vela-blue/theme.css'
// import 'primereact/resources/themes/vela-green/theme.css'
// import 'primereact/resources/themes/vela-orange/theme.css'
// import 'primereact/resources/themes/vela-purple/theme.css'
// import 'primereact/resources/themes/arya-blue/theme.css'
// import 'primereact/resources/themes/arya-green/theme.css'
// import 'primereact/resources/themes/arya-orange/theme.css'
// import 'primereact/resources/themes/arya-purple/theme.css'
// import 'primereact/resources/themes/nova/theme.css'
// import 'primereact/resources/themes/nova-alt/theme.css'
// import 'primereact/resources/themes/nova-accent/theme.css'
// import 'primereact/resources/themes/luna-amber/theme.css'
// import 'primereact/resources/themes/luna-blue/theme.css'
// import 'primereact/resources/themes/luna-green/theme.css'
// import 'primereact/resources/themes/luna-pink/theme.css'
// import 'primereact/resources/themes/rhea/theme.css'
 

import "primereact/resources/primereact.css"
import "primeicons/primeicons.css"
import "primeflex/primeflex.css"

let port
if (process.env.NODE_ENV === "development") {
  port = "https://protokol.kociuk.pl:3000"
} else {
  port = "https://protokol.kociuk.pl:3000"
}

const Login = () => {

    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const handleEnter = (e) => {
        if (e.keyCode === 13) {
handleSubmit()        }
    }
const handleSubmit = async () => {
        console.log(login, password)
        fetch(port + '/login', {
            method: 'POST',
            credentials: "include",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                login: login,
                password: password
            })
        })

            .then((response) =>{
            if (response.status === 200) {
                return response.text()//.json()
            } else {
                alert('Błędny login lub hasło')
            }}
            )
            .then(data => {
                console.log(data)
                if (data.error == null) {
                   window.location.href = '/'
               }
               else {
                   alert('Błędny login lub hasło')
                   console.log(data)
               }
            }) 
    }
return (
        <div>
            <div className="card">
                <img src={logo} className="logoGrupa"></img>
                <InputText className="inputEmail" placeholder="Enter email" value={login} type="email" onKeyDown={handleEnter} onChange={(e) => setLogin(e.target.value)}/>
                <Password className="inputPass" placeholder="Enter password" value={password} onKeyDown={handleEnter} onChange={(e) => setPassword(e.target.value)} toggleMask feedback={false}/>
                <Button className="buttonZaloguj" label="Zaloguj" icon="pi pi-user" type='submit' onClick={handleSubmit}></Button>
            </div> 
        </div>
    );
}
export default Login