import * as React from "react"
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  plPL,
} from "@mui/x-data-grid"
import Preview from "./preview"
import * as adres from "./adresy.js"

let port = adres.config.url

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  )
}

export default function DataTable() {
  const [rowsy, setRows] = React.useState([])

  React.useEffect(() => {
    fetch(port+"/wczytajProtokol", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setRows(data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  const columns = [
    { field: "ProtokolID", headerName: "ID", width: 70 },
    { field: "Nazwisko", headerName: "Kierowca", width: 250 },
    { field: "RegNo", headerName: "Nr rejestracyjny", width: 130 },
    {
      field: "CreateDate",
      headerName: "Data",
      width: 200,
    },
    {
      field: "Uwagi",
      headerName: "Uwagi",
      width: 350,
      editable: true,
    },
    {
      field: "akcje",
      headerName: "Akcje",
      width: 150,
      renderCell: (params) => <Preview stateChange={deleteItem} row={params.row} />,
    },
  
  ]

  const deleteItem = (id) => {
    console.log(id)
    console.log(rowsy)
    const newRows = rowsy.filter((row) => row.ProtokolID !== id)
    setRows(newRows)

    
    //setRows(rowsy.splice(id, 1))
  }

  const [selectionModel, setSelectionModel] = React.useState([])

  return (
    <div style={{ position: "relative", height: "100%", width: "100%" }}>
      <DataGrid
        localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
        components={{
          Toolbar: CustomToolbar,
        }}
        getRowId={(row) => row.ProtokolID}
        rows={rowsy}
        columns={columns}
        pageSize={12}
        rowsPerPageOptions={[12]}
        onSelectionModelChange={(newSelection) => {
          setSelectionModel(newSelection.selectionModel)
        }}
        selectionModel={selectionModel}
        
      />
    </div>
  )
}
