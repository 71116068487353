import "./css/aside.css"
import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import HelpIcon from '@mui/icons-material/Help';
import RvHookupIcon from '@mui/icons-material/RvHookup';
import AlbumTwoToneIcon from "@mui/icons-material/AlbumTwoTone";
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';

const drawerWidth = 240;

export default function Aside(props) {
  return (
    <Box sx={{ display: 'flex' }} className="aside">
      <CssBaseline />

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar />
        <Divider />
        <List>
          {['Samochód', 'Zgodne', 'Niezgodne', 'Pytania', 'Opony', 'Zamówienia'].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton onClick={() => props.handleZakladka(index)}>
                <ListItemIcon>
                  {index === 0 ? <LocalShippingIcon /> : index === 1 ? <CheckCircleIcon /> : index === 2 ? <CancelIcon /> : index === 3 ? <HelpIcon /> : index === 4 ? <AlbumTwoToneIcon /> : <ShoppingBasketIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {['Naczepa', 'Zgodne', 'Niezgodne', 'Pytania', 'Opony'].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton onClick={() => props.handleZakladka("Naczepa"+index)}>
                <ListItemIcon>
                 {index === 0 ? <RvHookupIcon /> : index === 1 ? <CheckCircleIcon /> : index === 2 ? <CancelIcon /> : index === 3 ? <HelpIcon /> : <AlbumTwoToneIcon />}
                </ListItemIcon>
                <ListItemText primary={text} /> 
              </ListItemButton>
            </ListItem>
          ))} 
        </List>
      </Drawer>
    </Box>
  );
}

