import * as React from "react"
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  plPL,
} from "@mui/x-data-grid"
import OponyPreview from "./oponyPreview"
import * as adres from "./adresy.js"

let port = adres.config.url

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  )
}

export default function DataTable() {
  const [rowsy, setRows] = React.useState([])

  React.useEffect(() => {
    fetch(port+"/listaAut", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setRows(data)
        console.log(data)
      })
      .catch((error) => {
        console.log(error)

      })
  }, [])

  const columns = [
    { field: "ID" },
    { field: "RegNo", headerName: "Nr rejestracyjny", width: 250 },
    { field: "Brand", headerName: "Marka", width: 250 },
    { field: "Aktywny", type: "boolean" },
    {
      field: "akcje",
      headerName: "Akcje",
      width: 150,
      renderCell: (params) => <OponyPreview row={params.row} />,
    },

  ]

  const [selectionModel, setSelectionModel] = React.useState([])

  return (
    <div style={{ position: "relative", height: "100%", width: "100%" }}>
      <DataGrid
        localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
        components={{
          Toolbar: CustomToolbar,
        }}
        getRowId={(row) => row.ID}
        rows={rowsy}
        columns={columns}
        onSelectionModelChange={(newSelection) => {
          setSelectionModel(newSelection.selectionModel)
        }}
        selectionModel={selectionModel}
        initialState={{
            columns: {
                columnVisibilityModel: {
                    ID: false,
                },
            },
            filter: {
                filterModel: {
                  items: [{ columnField: 'Aktywny', operatorValue: 'is', value: 'true' }],
                },
              },
          }}
      />
    </div>
  )
}
