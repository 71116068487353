import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import App from "./App"
import Login from "./login.jsx"
import reportWebVitals from "./reportWebVitals"

let port
if (process.env.NODE_ENV === "development") {
  port = "https://protokol.kociuk.pl:3000"
} else {
  port = "https://protokol.kociuk.pl:3000"
}

export default function Main() {
  const [token, setToken] = React.useState(false)
  const [wynik, setWynik] = React.useState(null)

  fetch(port + "/checkLogin", {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response.status === 200) {
        return response.text() //.json()
      }
    })
    .then((data) => {
        //console.log(data)
      if (data != null) {
        setToken(true)
      }
    })
  //console.log(token)

  // <React.StrictMode>
  //get token from local storage
  //if token is not null then render App
  //else render Login
  React.useEffect(() => {
   setWynik( 
      <Routes>
        <Route path="/" element={token ? <App /> : <Login />} /> 
        {/* <Route
          path="home"
          element={token ? <App /> : <Navigate replace to="/" />}
        /> */}
      </Routes>
   )
  }, [token])

  return (
    <BrowserRouter>{wynik}</BrowserRouter>
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
