import * as React from "react"
import "./css/preview.css"
import ManageSearchIcon from "@mui/icons-material/ManageSearch"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Fade from "@mui/material/Fade"
import Button from "@mui/material/Button"
import ToggleButton from "@mui/material/ToggleButton"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"
import ImageIcon from "@mui/icons-material/Image"
import LocalShippingIcon from "@mui/icons-material/LocalShipping"
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck"
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"
import Container from "@mui/material/Container"
import { DataGrid, selectedGridRowsCountSelector } from "@mui/x-data-grid"
import AlbumTwoToneIcon from "@mui/icons-material/AlbumTwoTone"
import { useTheme } from "@mui/material/styles"
import Viewer from "react-viewer"
import TaskAltIcon from "@mui/icons-material/TaskAlt"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Switch from "@mui/material/Switch"
import oponki from "./images/opony.png"
import TextField from "@mui/material/TextField"
import GrupaDIV from "./GrupaDIV"

import * as adres from "./adresy.js"

let port = adres.config.url

Object.filter = (obj, predicate) =>
  Object.keys(obj)
    .filter((key) => predicate(obj[key]))
    .reduce((res, key) => Object.assign(res, { [key]: obj[key] }), {})

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "80%",
  overflowY: "scroll",
  zIndex: 999,
}

export default function TransitionsModal(row) {
  let karuzela = []
  const [zdjeciaKaruzela, setKaruzela] = React.useState([])
  const theme = useTheme()
  const [activeStep, setActiveStep] = React.useState(0)
  const [maxSteps, setMaxSteps] = React.useState(zdjeciaKaruzela.length)
  const [biezniki, setBiezniki] = React.useState({})
  const [marki, setMarki] = React.useState({})
  //DOPISEK RAMKA Z LABELEM CUSTOM STYLE
  /*const CustomColorLabelledOutline = withStyles({
  root: {
    "& $notchedOutline": {
      borderColor: "purple"
    },
    "&:hover $notchedOutline": {
      borderColor: "orange"
    },
    "& $inputLabel": {
      color: "green"
    },
    "&:hover $inputLabel": {
      color: "blue"
    },
    "& $content": {
      color: "black"
    },
    "&:hover $content": {
      color: "purple"
    }
  },
  notchedOutline: {},
  inputLabel: {},
  content: {}
})(LabelledOutline);
//i później 
<CustomColorLabelledOutline>
Content
</CustomColorLabelledOutline>


*/

  const handleSwitch = async (event) => {
    await fetch(port + "/switchChange", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: event.target.id,
        wartosc: event.target.checked,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        fetchowanie(row)
      })
  }

  const [rozniceObj, setRozniceObj] = React.useState({})

  const fetchowanie = async (row) => {
    let current = {}
    let nazwyPytan = {}
    fetch(port + "/pytania", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw response
      })
      .then((data) => {
        data.forEach((el) => {
          nazwyPytan[el.id] = el.question.polski
        })
      })

        fetch(port + "/wczytajProtokolDetails", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            dane: row,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log(data)
            current = data
            Object.keys(current).forEach((el) => {
              Object.keys(nazwyPytan).find((key) => {
                if (key === current[el].Pytanie) {
                  current[el].Nazwa = nazwyPytan[key]
                }
              })
            })
            const currentSorted = ArraySorting(current, "TypDanych")
            let differences = currentSorted
            console.log(differences)

            for (const i in differences.file) {
              if (differences.file[i].Odpowiedz !== "") {
                karuzela.push({
                  key: i,
                  alt: differences.file[i].Pytanie,
                  src:
                    "https://ftp.kociuk.pl/uploads/" + differences.file[i].Odpowiedz,
                  downloadUrl:
                    "https://ftp.kociuk.pl/uploads/" + differences.file[i].Odpowiedz,
                  exportFileName: differences.file[i].Odpowiedz,
                })
              }
            }
            let differencesTemp = {}

            for (const i in differences) {
              differencesTemp[i] = Object.filter(
                differences[i],
                (x) => x.PoprawnoscDanych !== true
              )
            }
            differences = differencesTemp
            setKaruzela(karuzela)
            setRozniceObj({
              daty: differences.date ? Object.values(differences.date) : [],
              wartosci: differences.checkbox
                ? Object.values(differences.checkbox ?? {})
                : [],
              eksploatacja:
                differences.number || differences.text || differences.radio
                  ? Object.values(differences.number ?? {})
                      .concat(Object.values(differences.text ?? {}))
                      .concat(Object.values(differences.radio ?? {}))
                  : [],
              zdjecia: Object.values(zdjeciaKaruzela),
            })
            //move inputs from diffrences array wher pytanie contains bieznik to new object with key that is the same as pytanie
            // if differences.input.length < 12 then insert missing elements checking pytanie from currentSorted
            //   console.log(currentSorted)
            differences.input = {}
            let counter = 0
            currentSorted.input.forEach((el) => {
              differences.input[counter] = {
                ProtokolDetailID: el.ProtokolDetailID,
                //Bylo: previousSorted.input[counter].Odpowiedz,
                Jest: el.Odpowiedz ?? el.Jest,
                PoprawnoscDanych: false,
                Pytanie: el.Pytanie,
                TypDanych: el.TypDanych,
              }
              counter++
            })
            // BIEŻNICZKI KOLORKI ITP Z ŻÓŁTYM ZAMIAST SRAKI
            /*
                        let biezniczki = {}
            for (const i in differences) {
              for (const j in differences[i]) {
                if (differences[i][j].Pytanie.includes("bieznik")) {
                  biezniczki[differences[i][j].Pytanie] = differences[i][j]
                  biezniczki[differences[i][j].Pytanie].scale =
                    differences[i][j].Jest > 15 ? 15 : differences[i][j].Jest
                  biezniczki[differences[i][j].Pytanie].colorString =
                    differences[i][j].Jest < 7.5
                      ? "rgb(255, " +
                        parseInt(0 + parseInt(differences[i][j].scale) * 34) +
                        ", 0)"
                      : "rgb(" +
                        parseInt(0 + parseInt(differences[i][j].scale) * 34) +
                        ", 255, 0)"
                }
              }
            }
            console.log(biezniczki)
            setBiezniki(biezniczki)*/

            let biezniczki = {}
            for (const i in differences) {
              for (const j in differences[i]) {
                if (differences[i][j].Pytanie.includes("bieznik")) {
                  biezniczki[differences[i][j].Pytanie] = differences[i][j]
                  biezniczki[differences[i][j].Pytanie].scale =
                    differences[i][j].Jest > 15 ? 15 : differences[i][j].Jest
                  biezniczki[differences[i][j].Pytanie].colorString =
                    "rgb(" +
                    parseInt(255 - parseInt(differences[i][j].scale) * 17) +
                    ", " +
                    parseInt(0 + parseInt(differences[i][j].scale) * 17) +
                    ", 0)"
                }
              }
            }
            console.log(biezniczki)
            setBiezniki(biezniczki)

            let mareczki = {}
            for (const i in differences) {
              for (const j in differences[i]) {
                if (differences[i][j].Pytanie.includes("marka")) {
                  mareczki[differences[i][j].Pytanie] = differences[i][j]
                }
              }
            }
            console.log(mareczki)
            setMarki(mareczki)
            setMaxSteps(zdjeciaKaruzela.length)
          })
      
  }

  const columns = [
    { field: "ProtokolDetailID", headerName: "ID", width: 90, type: "number" },
    {
      field: "Pytanie",
      headerName: "Pytanie",
      type: "string",
      width: 250,
    },
    {
      field: "Odpowiedz",
      headerName: "Aktualna wartość",
      type: "string",
      width: 250,
      valueGetter: ({ row }) => {
        if (row.Odpowiedz === "true") {
          return "Tak"
        }
        else if (row.Odpowiedz === "false") {
          return "Nie"
      }
        else {
          return row.Odpowiedz
        }
      }
    }
  ]

  function ArraySorting(array, key) {
    return array.reduce((acc, item) => {
      const keyValue = item[key]
      if (!acc[keyValue]) {
        acc[keyValue] = []
      }
      acc[keyValue].push(item)
      return acc
    }, {})
  }

  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const podgladClick = () => {
    fetchowanie(row)
    handleOpen()
  }
  const [otwTerminy, setOtwTerminy] = React.useState(false)
  const [otwWartosci, setOtwWartosci] = React.useState(false)
  const [otwEksploatacja, setOtwEksploatacja] = React.useState(false)
  const [otwZdjecia, setOtwZdjecia] = React.useState(false)
  const [otwOpony, setOtwOpony] = React.useState(false)
  const [selectedTerminy, setSelectedTerminy] = React.useState(false)
  const [selectedWartosci, setSelectedWartosci] = React.useState(false)
  const [selectedEksploatacja, setSelectedEksploatacja] = React.useState(false)
  const [selectedZdjecia, setSelectedZdjecia] = React.useState(false)
  const [selectedOpony, setSelectedOpony] = React.useState(false)

  const openTerminy = () => {
    setOtwTerminy(!otwTerminy)
    setSelectedTerminy(!selectedTerminy)
  }
  const openWartosci = () => {
    setOtwWartosci(!otwWartosci)
    setSelectedWartosci(!selectedWartosci)
  }
  const openEksploatacja = () => {
    setOtwEksploatacja(!otwEksploatacja)
    setSelectedEksploatacja(!selectedEksploatacja)
  }
  const openZdjecia = () => {
    setOtwZdjecia(!otwZdjecia)
    setSelectedZdjecia(!selectedZdjecia)
  }

  const openOpony = () => {
    setOtwOpony(!otwOpony)
    setSelectedOpony(!selectedOpony)
  }

  const [visible, setVisible] = React.useState(true)
  return (
    <div>
      <Button startIcon={<ManageSearchIcon />} onClick={podgladClick}>
        Podgląd
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Podgląd
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                <ToggleButton
                  variant="contained"
                  value="check"
                  color="primary"
                  className="material-button"
                  selected={selectedTerminy}
                  onChange={() => {
                    openTerminy()
                  }}
                >
                  <CalendarMonthIcon />
                  Terminy
                </ToggleButton>
                <ToggleButton
                  variant="contained"
                  className="material-button"
                  value="check"
                  color="primary"
                  selected={selectedWartosci}
                  onChange={() => {
                    openWartosci()
                  }}
                >
                  <LibraryAddCheckIcon />
                  Wartości
                </ToggleButton>
                <ToggleButton
                  variant="contained"
                  value="check"
                  color="primary"
                  className="material-button"
                  selected={selectedEksploatacja}
                  onChange={() => {
                    openEksploatacja()
                  }}
                >
                  <LocalShippingIcon />
                  Eksploatacja
                </ToggleButton>
                <ToggleButton
                  variant="contained"
                  value="check"
                  color="primary"
                  className="material-button"
                  selected={selectedOpony}
                  onChange={() => {
                    openOpony()
                  }}
                >
                  <AlbumTwoToneIcon />
                  Opony
                </ToggleButton>
              <ToggleButton
                variant="contained"
                value="check"
                color="primary"
                className="material-button"
                selected={selectedZdjecia}
                onChange={() => {
                  openZdjecia()
                }}
              >
                <ImageIcon />
                Zdjęcia
              </ToggleButton>
            </Typography>
            {otwTerminy && (
              <Container sx={{ height: 400, width: "100%", mt: 5 }}>
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                >
                  Terminy
                </Typography>
                <DataGrid
                  getRowId={(row) => row.ProtokolDetailID}
                  rows={rozniceObj.daty}
                  columns={columns}
                  disableVirtualization
                />
              </Container>
            )}
            {otwWartosci && (
              <Container sx={{ height: 400, width: "100%", mt: 5 }}>
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                >
                  Wartości
                </Typography>
                <DataGrid
                  getRowId={(row) => row.ProtokolDetailID}
                  rows={rozniceObj.wartosci}
                  columns={columns}
                  disableVirtualization
                />
              </Container>
            )}
            {otwEksploatacja && (
              <Container sx={{ height: 400, width: "100%", mt: 5 }}>
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                >
                  Eksploatacja
                </Typography>
                <DataGrid
                  getRowId={(row) => row.ProtokolDetailID}
                  rows={rozniceObj.eksploatacja}
                  columns={columns}
                  disableVirtualization
                />
              </Container>
            )}
            {otwOpony && (
              <Container sx={{ height: 400, width: "100%" }}>
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                >
                  Opony
                </Typography>
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <img
                    //style={{ position: "absolute" }}
                    src={oponki}
                    id="truck"
                  />
                  <GrupaDIV
                    label="Lewy przód"
                    stylowa={{ left: "-130px", top: "310px" }}
                  >
                    <TextField
                      disabled
                      sx={{
                        width: "10ch",
                        marginRight: "5px",
                        WebkitTextFillColor: biezniki.bieznikLF.colorString,
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: biezniki.bieznikLF.colorString,
                        },
                      }}
                      margin="dense"
                      size="small"
                      id="outlined-disabled"
                      label={"Bieżnik"}
                      defaultValue={biezniki.bieznikLF.Jest}
                    />
                    <TextField
                      disabled
                      sx={{
                        width: "15ch",
                        WebkitTextFillColor: "#909090",
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#909090",
                        },
                      }}
                      size="small"
                      margin="dense"
                      id="outlined-disabled"
                      label="Marka"
                      defaultValue={marki.markaLF.Jest}
                    />
                  </GrupaDIV>
                  <GrupaDIV
                    label="Prawy przód"
                    stylowa={{ left: "-130px", top: "5px" }}
                  >
                    <TextField
                      disabled
                      sx={{
                        width: "10ch",
                        marginRight: "5px",
                        WebkitTextFillColor: biezniki.bieznikRF.colorString,
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: biezniki.bieznikRF.colorString,
                        },
                      }}
                      margin="dense"
                      size="small"
                      id="outlined-disabled"
                      label="Bieżnik"
                      defaultValue={biezniki.bieznikRF.Jest}
                    />
                    <TextField
                      disabled
                      sx={{
                        width: "15ch",
                        WebkitTextFillColor: "#909090",
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#909090",
                        },
                      }}
                      size="small"
                      margin="dense"
                      id="outlined-disabled"
                      label="Marka"
                      defaultValue={marki.markaRF.Jest}
                    />
                  </GrupaDIV>
                  <GrupaDIV
                    label="Lewy tył zewnętrzny"
                    stylowa={{ right: "-130px", top: "310px" }}
                  >
                    <TextField
                      disabled
                      sx={{
                        width: "10ch",
                        marginRight: "5px",
                        WebkitTextFillColor: biezniki.bieznikLBZ.colorString,
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: biezniki.bieznikLBZ.colorString,
                        },
                      }}
                      margin="dense"
                      size="small"
                      id="outlined-disabled"
                      label="Bieżnik"
                      defaultValue={biezniki.bieznikLBZ.Jest}
                    />
                    <TextField
                      disabled
                      sx={{
                        width: "15ch",
                        WebkitTextFillColor: "#909090",
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#909090",
                        },
                      }}
                      size="small"
                      margin="dense"
                      id="outlined-disabled"
                      label="Marka"
                      defaultValue={marki.markaLBZ.Jest}
                    />
                  </GrupaDIV>{" "}
                  <GrupaDIV
                    label="Lewy tył wewnętrzny"
                    stylowa={{ right: "-130px", top: "222px" }}
                  >
                    <TextField
                      disabled
                      sx={{
                        width: "10ch",
                        marginRight: "5px",
                        WebkitTextFillColor: biezniki.bieznikLBW.colorString,
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: biezniki.bieznikLBW.colorString,
                        },
                      }}
                      margin="dense"
                      size="small"
                      id="outlined-disabled"
                      label="Bieżnik"
                      defaultValue={biezniki.bieznikLBW.Jest}
                    />
                    <TextField
                      disabled
                      sx={{
                        width: "15ch",
                        WebkitTextFillColor: "#909090",
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#909090",
                        },
                      }}
                      size="small"
                      margin="dense"
                      id="outlined-disabled"
                      label="Marka"
                      defaultValue={marki.markaLBW.Jest}
                    />
                  </GrupaDIV>{" "}
                  <GrupaDIV
                    label="Prawy tył zewnętrzny"
                    stylowa={{ right: "-130px", top: "5px" }}
                  >
                    <TextField
                      disabled
                      sx={{
                        width: "10ch",
                        marginRight: "5px",
                        WebkitTextFillColor: biezniki.bieznikRBZ.colorString,
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: biezniki.bieznikRBZ.colorString,
                        },
                      }}
                      margin="dense"
                      size="small"
                      id="outlined-disabled"
                      label="Bieżnik"
                      defaultValue={biezniki.bieznikRBZ.Jest}
                    />
                    <TextField
                      disabled
                      sx={{
                        width: "15ch",
                        WebkitTextFillColor: "#909090",
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#909090",
                        },
                      }}
                      size="small"
                      margin="dense"
                      id="outlined-disabled"
                      label="Marka"
                      defaultValue={marki.markaRBZ.Jest}
                    />
                  </GrupaDIV>{" "}
                  <GrupaDIV
                    label="Prawy tył wewnętrzny"
                    stylowa={{ right: "-130px", top: "93px" }}
                  >
                    <TextField
                      disabled
                      sx={{
                        width: "10ch",
                        marginRight: "5px",
                        WebkitTextFillColor: biezniki.bieznikRBW.colorString,
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: biezniki.bieznikRBW.colorString,
                        },
                      }}
                      margin="dense"
                      size="small"
                      id="outlined-disabled"
                      label="Bieżnik"
                      defaultValue={biezniki.bieznikRBW.Jest}
                    />
                    <TextField
                      disabled
                      sx={{
                        width: "15ch",
                        WebkitTextFillColor: "#909090",
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#909090",
                        },
                      }}
                      size="small"
                      margin="dense"
                      id="outlined-disabled"
                      label="Marka"
                      defaultValue={marki.markaRBW.Jest}
                    />
                  </GrupaDIV>
                </div>
                {/* <figure>
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 346 148"
                    preserveAspectRatio="xMinYMin meet"
                    id="samochodzik"
                  >
                    <image width="200" xlinkHref={oponki} id="truck" /> */}
                {/* <rect
                      target=""
                      alt="RF"
                      title="RF"
                      href=""
                      coords="35,11,194,57"
                      shape="rect"
                    ></rect>
                    <rect
                      target=""
                      alt="LF"
                      title="LF"
                      href=""
                      coords="33,242,194,288"
                      shape="rect"
                    ></rect>
                    <rect
                      target=""
                      alt="RBZ"
                      title="RBZ"
                      href=""
                      coords="491,17,679,52"
                      shape="rect"
                    ></rect>
                    <rect
                      target=""
                      alt="RBW"
                      title="RBW"
                      href=""
                      coords="491,53,680,85"
                      shape="rect"
                    ></rect>
                    <rect
                      target=""
                      alt="LBW"
                      title="LBW"
                      href=""
                      coords="491,214,681,248"
                      shape="rect"
                    ></rect>
                    <rect
                      target=""
                      alt="LBZ"
                      title="LBZ"
                      href=""
                      coords="491,249,681,283"
                      shape="rect"
                    ></rect> */}
                {/* </svg>
                </figure> */}
              </Container>
            )}
            {otwZdjecia && (
              <Viewer
                zIndex={1400}
                visible={visible}
                onClose={() => {
                  setOtwZdjecia(false)
                  setSelectedZdjecia(!selectedZdjecia)
                }}
                onMaskClick={() => {
                  setOtwZdjecia(false)
                  setSelectedZdjecia(!selectedZdjecia)
                }}
                images={zdjeciaKaruzela}
                downloadInNewWindow={true}
                zoomSpeed={1}
                minScale={1}
                downloadable={true}
              />
            )}
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              <Button
                variant="contained"
                startIcon={<TaskAltIcon />}
                className="material-button"
                onClick={handleClose}
              >
                Zatwierdź
              </Button>
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  )
}