import React, { useState, useEffect, useRef } from "react"
// import Checkbox from "./zamowieniaCheck"
import { Checkbox } from "primereact/checkbox"
import * as adres from "./adresy.js"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Button } from "primereact/button"
import { Tag } from "primereact/tag"
import { InputSwitch } from "primereact/inputswitch"
import printJS from "print-js"
import { Toast } from "primereact/toast"
import "primereact/resources/themes/md-light-indigo/theme.css"
let port = adres.config.url

export default function Zamowienia() {
  const [rowsy, setRows] = useState([])
  const [rowsDetails, setRowsDetails] = useState([])
  const [loading, setLoading] = useState(false)
  const [showAll, setShowAll] = useState(false)
  const [expandedRows, setExpandedRows] = useState(null)
  const toast = useRef(null)

  const allowExpansion = (rowData) => {
    //if exists orders in rowsDetails for this row with the same ID

    const orders = rowsDetails.filter((row) => row.Zamowienie_ID === rowData.ID)
    if (orders.length > 0) {
      rowData.orders = orders
      console.log(rowData)
      console.log(orders)

      // return true
    }

    return rowData.orders?.length > 0
  }

  const rowExpansionTemplate = (data) => {
    return (
      <div className="p-3">
        <DataTable
          value={data.orders}
          tableStyle={{ width: "100%", fontSize: "0.9rem" }}
        >
          <Column
            field="ID"
            header="ID"
            sortable
            bodyStyle={{ backgroundColor: "#e3dfd5" }}
            headerStyle={{ backgroundColor: "#e3dfd5" }}
          />
          <Column
            field="Nazwa"
            header="Nazwa"
            sortable
            bodyStyle={{ backgroundColor: "#e3dfd5" }}
            headerStyle={{ backgroundColor: "#e3dfd5" }}
          />
          <Column
            field="Ilosc"
            header="Ilość"
            sortable
            bodyStyle={{ backgroundColor: "#e3dfd5" }}
            headerStyle={{ backgroundColor: "#e3dfd5" }}
          />
          <Column
            field="CreatedDate"
            header="Data utworzenia"
            sortable
            bodyStyle={{ backgroundColor: "#e3dfd5" }}
            headerStyle={{ backgroundColor: "#e3dfd5" }}
          />
          <Column
            field="DataRealizacji"
            header="Data realizacji"
            sortable
            bodyStyle={{ backgroundColor: "#e3dfd5" }}
            headerStyle={{ backgroundColor: "#e3dfd5" }}
          />
          {/* <Column
            field="StatusID"
            header="Status"
            headerStyle={{ backgroundColor: "#e3dfd5" }}
            sortable
            body={(rowData) =>
              rowData.StatusID === 1 ? (
                <span>Przyjęte</span>
              ) : (
                <span>Zrealizowane</span>
              )
            }
            bodyStyle={{ backgroundColor: "#e3dfd5" }}
          />
          <Column
            header="Wykonane"
            headerStyle={{ backgroundColor: "#e3dfd5" }}
            body={(rowData) => (
              <Checkbox
                checked={rowData.Done}
                // row={rowData}
                value={rowData}
                onChange={(e) => {
                  const rowsDetailsTemp = [...rowsDetails]
                  const index = rowsDetailsTemp.findIndex(
                    (row) => row.ID === e.value.ID
                  )
                  rowsDetailsTemp[index].Done = e.checked
                  rowsDetailsTemp[index].StatusID = e.checked === true ? 2 : 1
                  setRowsDetails(rowsDetailsTemp)
                }}
              />
            )}
            bodyStyle={{ backgroundColor: "#e3dfd5" }}
          /> */}
        </DataTable>
      </div>
    )
  }

  const drukujWszystko = () => {
    let dataToPrintTemp = []
    let columnsToPrintTemp = [
      "RegNo",
      "Name",
      "ForName",
      "Nazwa",
      "NazwaRU",
      "Ilosc",
      "CreatedDate",
    ]
    //drukuj ze wszystkimi elementami podrzędnymi
    rowsy.forEach((row) => {
      rowsDetails.forEach((rowDetails) => {
        if (rowDetails.DataRealizacji === null) {
          if (row.ID === rowDetails.Zamowienie_ID) {
            let temp = {}
            // let columns = []
            for (const [key, value] of Object.entries(row)) {
              temp[key] = value
              // columns.push(key)
            }
            for (const [key, value] of Object.entries(rowDetails)) {
              temp[key] = value
              // columns.push(key)
            }
            dataToPrintTemp.push(temp)
            // columnsToPrintTemp = columns
          }
        }
      })
    })
    // rowsDetails.forEach((row) => {
    //   let temp = {}
    //   let columns = []
    //   for (const [key, value] of Object.entries(row)) {
    //     temp[key] = value
    //     columns.push(key)
    //   }
    //   dataToPrintTemp.push(temp)
    //   columnsToPrintTemp = columns
    // })

    // rowsy.forEach((row) => {
    //   let temp = {}
    //   let columns = []
    //   for (const [key, value] of Object.entries(row)) {
    //     if (key !== "orders") {
    //       temp[key] = value
    //       columns.push(key)
    //     }
    //   }
    //   dataToPrintTemp.push(temp)
    //   columnsToPrintTemp = columns
    // })
    // console.log(dataToPrintTemp)
    // console.log(columnsToPrintTemp)

    printJS({
      printable: dataToPrintTemp,
      properties: columnsToPrintTemp,
      type: "json",
      gridHeaderStyle:
        "color: #2c2d2e; border: 2px solid #3971A5; font-family: Roboto, Helvetica, Arial, sans-serif; font-size: 11px;",
      gridStyle:
        "border: 2px solid #3971A5; font-family: Roboto, Helvetica, Arial, sans-serif; font-size: 11px;",
    })
  }

  const header = (
    <div
      className="flex flex-wrap gap-2 
    align-items-center"
    >
      <InputSwitch
        tooltip="Pokaż wszystkie"
        tooltipOptions={{ position: "top" }}
        checked={showAll}
        style={{ margin: "10px" }}
        onChange={(e) => setShowAll(e.target.value)}
      />
      <Button
        label="Drukuj"
        style={{ margin: "10px" }}
        onClick={drukujWszystko}
        icon="pi pi-print"
        className="p-button-raised p-button-rounded"
      />
    </div>
  )

  useEffect(() => {
    setLoading(true)
    fetch(port + "/zamowienia", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        data = data.map((row) => {
          row.Done = row.StatusID === 3 ? true : false
          return row
        })
        setRows(data)
        console.log(data)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        // setLoading(false)
      })

    fetch(port + "/zamowieniaDetails", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        data = data.map((row) => {
          row.Done = row.StatusID === 2 ? true : false
          return row
        })
        setRowsDetails(data)
        console.log(data)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        // setLoading(false)
      })
    setLoading(false)
  }, [])

  const onRowClick = (rowData) => {
    if (rowData.orders?.length > 0) {
      let _expandedRows = { ...expandedRows }
      if (_expandedRows[rowData.ID]) {
        delete _expandedRows[rowData.ID]
      } else {
        _expandedRows[rowData.ID] = true
      }
      setExpandedRows(_expandedRows)
    }
  }

  return (
    <div style={{ position: "relative", height: "100%", width: "100%" }}>
      <Toast ref={toast} style={{ zIndex: 1000 }} />
      <DataTable
        value={showAll ? rowsy : rowsy.filter((row) => row.StatusID !== 3)}
        expandedRows={expandedRows}
        onRowClick={(e) => onRowClick(e.data)}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
        dataKey="ID"
        header={header}
        paginator
        rows={10}
        rowsPerPageOptions={[5, 10, 20]}
        loading={loading}
        size="small"
        removableSort
        // responsive
        tableStyle={{ width: "100%", fontSize: "0.9rem", maxHeight: "70vh" }}
      >
        <Column expander={allowExpansion} style={{ width: "3rem" }} />
        <Column
          field="ID"
          header="ID"
          sortable
          style={{ width: "4rem" }}
          bodyStyle={{ cursor: "pointer" }}
        ></Column>
        <Column
          field="RegNo"
          header="Numer rejestracyjny"
          sortable
          style={{ width: "17rem" }}
          bodyStyle={{ cursor: "pointer" }}
        ></Column>
        <Column
          field="Name"
          header="Nazwisko"
          sortable
          bodyStyle={{ cursor: "pointer" }}
        ></Column>
        <Column
          field="ForName"
          header="Imię"
          sortable
          bodyStyle={{ cursor: "pointer" }}
        ></Column>
        <Column
          field="Expr1"
          bodyStyle={{ cursor: "pointer" }}
          header="Data"
          sortable
          body={(rowData) => <span>{rowData.Expr1}</span>}
        />
        <Column
          field="StatusID"
          header="Status"
          sortable
          bodyStyle={{ cursor: "pointer" }}
          body={(rowData) =>
            rowData.StatusID === 1 ? (
              <span>Przyjęte</span>
            ) : rowData.StatusID === 2 ? (
              <span>Niekompletne</span>
            ) : (
              <span>Zrealizowane</span>
            )
          }
        />
        {/* <Column
          header="Podgląd"
          bodyStyle={{ cursor: "pointer" }}
          body={(rowData) => <ZamowieniaPreview row={rowData} />}
        /> */}
        <Column
          header="Wykonane"
          bodyStyle={{ cursor: "pointer" }}
          body={(rowData) => (
            <Checkbox
              checked={rowData.Done}
              // row={rowData}
              value={rowData}
              disabled={rowData.StatusID === 3 ? true : false}
              onChange={(e) => {
                const rowsyTemp = [...rowsy]
                const index = rowsyTemp.findIndex(
                  (row) => row.ID === e.value.ID
                )
                rowsyTemp[index].Done = e.checked
                rowsyTemp[index].StatusID = e.checked === true ? 3 : 1
                //jeśli true to ustaw status podrzędnych elementów na 2
                setRows(rowsyTemp)

                if (e.checked === true) {
                  setRowsDetails(
                    rowsDetails.map((row) => {
                      if (row.Zamowienie_ID === e.value.ID) {
                        row.StatusID = 2
                        row.DataRealizacji = new Date()
                          .toLocaleString("sv-SE")
                          .split(" ")[0]
                        console.log(row, e.value, row.Zamowienie_ID, e.value.ID)
                      }
                      return row
                    })
                  )
                  fetch(port + "/zamowieniaCheck", {
                    method: "POST",
                    // credentials: "include",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      id: e.value.ID,
                    }),
                  }).then((response) => {
                    if (response.status === 200) {
                      return response.text()
                    } else {
                      alert("Błąd")
                    }
                  })
                }
              }}
            />
          )}
        />
      </DataTable>
    </div>
  )
}
